import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Box, Button, CardActions, Grid, styled, TextField, Theme } from "@mui/material";
import { useContext, useEffect, useState, FocusEvent } from "react";
import { APIContext } from "../context/apicontext";
import { UserContext } from "../context/usercontext";
import { ThemeSettingsContext } from "../context/themesettingscontext";
import { HearderUI } from "../components/header";
import { t, Trans } from "@lingui/macro";
import { ThreeTabs } from "../components/tabs";
import { ConsentDetail } from "../components/consentframe";
import { CardDetail } from "../components/cardframe";
import { Constants, FixedSizes } from "../constants/constants";
import { IntegrationSettingsContext } from "../context/integrationsettingscontext";
import { ConsoleType, ProfilePageItems, WidgetSize } from "../types/enum";
import CircularStatic from "../components/progress";
import { AddressDTO, MemberDTO } from "../apiclient/MemberAPI";
import { log } from "../service/clientlog";
import { format } from "date-fns";
import { emailPattern } from "../types/interface";
import { hexToRGBA } from "../utils/themeStyling";
import Typography from "@mui/material/Typography";

export const CssTextField = styled(TextField)(({ theme }) => ({
	"& label.Mui-focused": {
		color: theme.palette.secondary.light,
		fontFamily: theme.typography.fontFamily,
	},
	"& label": {
		color: theme.palette.secondary.light,
		fontFamily: theme.typography.fontFamily,
	},
	"& label:hover": {
		color: theme.palette.secondary.light,
		fontFamily: theme.typography.fontFamily,
	},
	"& .MuiOutlinedInput-root": {
		color: theme.palette.secondary.main,
		fontFamily: theme.typography.fontFamily,
		"& fieldset": {
			borderColor: theme.palette.secondary.dark,
			fontFamily: theme.typography.fontFamily,
			borderRadius: "5px",
		},
		"&:hover fieldset": {
			borderColor: theme.palette.secondary.light,
			fontFamily: theme.typography.fontFamily,
		},
		"&.Mui-focused fieldset": {
			borderWidth: 2,
			borderColor: theme.palette.secondary.light,
			fontFamily: theme.typography.fontFamily,
		},
	},
	input: {
		"&:required:invalid::-webkit-datetime-edit": {
			color: "transparent !important",
			opacity: "var(--field-opacity, 0)",
		},
		"&:required:invalid::-webkit-datetime-edit-day-field": {
			color: "transparent !important",
			opacity: "var(--field-opacity, 0)",
		},
		"&:required:invalid::-webkit-datetime-edit-month-field": {
			color: "transparent !important",
			opacity: "var(--field-opacity, 0)",
		},
		"&:required:invalid::-webkit-datetime-edit-year-field": {
			color: "transparent !important",
			opacity: "var(--field-opacity, 0)",
		},
		"&:required::-webkit-datetime-edit-year-field": {
			color: "black !important",
			"--field-opacity": "1",
		},
		"&:required::-webkit-datetime-edit-month-field": {
			color: "black !important",
			"--field-opacity": "1",
		},
		"&:required::-webkit-datetime-edit-day-field": {
			color: "black !important",
			"--field-opacity": "1",
		},
		"&:focus::-webkit-datetime-edit": {
			color: "black !important",
			"--field-opacity": "1",
		},
	},
}));

interface Props {
	memberDTO: MemberDTO;
	showAllFields: boolean;
	editable: boolean;
}

function ProfileDetail(props: Props) {
	const authContext = useContext(APIContext);
	const [processingUpdate, setProcessingUpdate] = useState<boolean>(false);
	const [animation, setAnimation] = useState<boolean>(false);
	const [emailError, setEmailError] = useState(false);
	const [emailErrorMessage, setEmailErrorMessage] = useState<null | String>(null);
	const [animationColor, setAnimationColor] = useState("");

	// on return back from adding card (via storebox)
	useEffect(() => {
		if (window.location.search.includes(Constants.StoreboxSearchParam)) {
			authContext.cardInfo({ loadAPI: true });
			window.history.replaceState({}, document.title, "/");
		}
	}, []);
	useEffect(() => {
		if (!emailError) {
			setEmailErrorMessage(null);
			return;
		}
		setEmailErrorMessage(t`Please enter correct email address.`);
	}, [emailError]);

	useEffect(() => {
		if (animation) {
			setTimeout(() => {
				setAnimation(false);
			}, 2000);
		}
	}, [animation]);

	const handleEmailBlur = (e: FocusEvent<HTMLInputElement>) => {
		if (emailError && emailPattern.test(e.target.value)) setEmailError(false);
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		try {
			setEmailError(false);
			setProcessingUpdate(true);
			const data = new FormData(event.currentTarget);
			const email = data.get("email") as string;
			const birthDate = (data.get("birthDate") as string).length > 0 ? (data.get("birthDate") as string) : null;
			if (email !== props.memberDTO.emailAddress && !emailPattern.test(email)) {
				setEmailError((current) => !current);
				setProcessingUpdate(false);
				return;
			}
			const address: AddressDTO = {
				addressType: props.memberDTO.address?.addressType,
				city: data.get("city") as string,
				country: props.memberDTO.address?.country,
				countryCode: props.memberDTO.address?.countryCode,
				street: data.get("address") as string,
				zipCode: data.get("postCode") as string,
			};
			const member: MemberDTO = {
				cellphoneNumber: props.memberDTO.cellphoneNumber,
				id: props.memberDTO.id,
				address: address,
				birthDate: birthDate,
				emailAddress: email,
				firstName: data.get("firstName") as string,
				gender: props.memberDTO.gender,
				lastName: data.get("lastName") as string,
				programTag: props.memberDTO.programTag,
				userAgreementAcceptDate: props.memberDTO.userAgreementAcceptDate,
			};
			await authContext.updateInfo(member);
			setAnimationColor("darkgreen");
		} catch (error: any) {
			log("Member update", ConsoleType.Error, error);
			setAnimationColor("darkred");
			setAnimation(false);
			if (error.status === 401) log("Relogin in order to get valid token.", ConsoleType.Warning);
		} finally {
			setProcessingUpdate(false);
			setAnimation(true);
		}
	};
	return (
		<Box onSubmit={handleSubmit} noValidate component="form" autoComplete="off">
			<Grid container sx={{ mt: 2 }}>
				<Grid item xs={12} sx={{ mb: 2 }}>
					<CssTextField
						label={<Trans>Firstname</Trans>}
						variant="outlined"
						size="small"
						name="firstName"
						id="firstName"
						fullWidth
						defaultValue={props.memberDTO.firstName}
						inputProps={{ readOnly: !props.editable || processingUpdate, autoComplete: "new-password" }}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} sx={{ mb: 2 }}>
					<CssTextField
						label={<Trans>Lastname</Trans>}
						variant="outlined"
						size="small"
						name="lastName"
						id="lastName"
						fullWidth
						defaultValue={props.memberDTO.lastName}
						inputProps={{ readOnly: !props.editable || processingUpdate, autoComplete: "new-password" }}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				{props.showAllFields && (
					<Grid item xs={12} sx={{ mb: 2 }}>
						<CssTextField
							label={<Trans>Birth Date</Trans>}
							variant="outlined"
							size="small"
							name="birthDate"
							id="birthDate"
							fullWidth
							defaultValue={props.memberDTO.birthDate && format(new Date(props.memberDTO.birthDate), "yyyy-MM-dd")}
							required={true}
							inputProps={{
								readOnly: !props.editable || processingUpdate,
								type: "date",
							}}
							InputLabelProps={{
								shrink: true,
							}}
							sx={{
								"& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
									color: "transparent",
								},
							}}
						/>
					</Grid>
				)}
				{props.showAllFields && (
					<Grid item xs={12} sx={{ mb: 2 }}>
						<CssTextField
							label={<Trans>Address</Trans>}
							variant="outlined"
							size="small"
							name="address"
							id="address"
							fullWidth
							defaultValue={props.memberDTO.address?.street}
							inputProps={{ readOnly: !props.editable || processingUpdate, autoComplete: "new-password" }}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Grid>
				)}
				{props.showAllFields && (
					<Grid item xs={6} sm={6} sx={{ mb: 2, paddingRight: 1 }}>
						<CssTextField
							label={<Trans>Post Code</Trans>}
							variant="outlined"
							size="small"
							name="postCode"
							id="postCode"
							defaultValue={props.memberDTO.address?.zipCode}
							inputProps={{ readOnly: !props.editable || processingUpdate, autoComplete: "new-password" }}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Grid>
				)}
				{props.showAllFields && (
					<Grid item xs={6} sm={6} sx={{ mb: 2 }}>
						<CssTextField
							label={<Trans>City</Trans>}
							variant="outlined"
							size="small"
							name="city"
							id="city"
							defaultValue={props.memberDTO.address?.city}
							inputProps={{ readOnly: !props.editable || processingUpdate, autoComplete: "new-password" }}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Grid>
				)}
				<Grid item xs={12} sx={{ mb: 2 }}>
					<CssTextField
						label={<Trans>Email</Trans>}
						variant="outlined"
						size="small"
						name="email"
						id="email"
						fullWidth
						error={emailError}
						helperText={emailErrorMessage}
						defaultValue={props.memberDTO.emailAddress}
						onBlur={handleEmailBlur}
						inputProps={{ readOnly: !props.editable || processingUpdate, autoComplete: "new-password" }}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} sx={{ mb: 2 }}>
					<CssTextField
						label={<Trans>Phone number</Trans>}
						variant="outlined"
						size="small"
						name="tel"
						id="tel"
						fullWidth
						defaultValue={props.memberDTO.cellphoneNumber}
						inputProps={{
							readOnly: true,
							sx: {
								bgcolor: (theme: Theme) => {
									return hexToRGBA(theme.palette.background.paper, "0.1");
								},
								borderRadius: "5px",
							},
						}}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} sx={{ mb: 2 }}>
					{props.editable && (
						<Button
							sx={{
								bgcolor: (theme) => theme.palette.primary.dark,
								color: (theme) => theme.palette.text.secondary,
								border: (theme) => (theme.palette.mode === "light" ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.primary.dark}`),
								marginBottom: 2,
								height: "30px",
								width: "100%",
							}}
							type="submit"
							variant="contained"
							size="small"
							id="confirmall"
							name="confirmall"
							disabled={processingUpdate}
							onClick={() => handleSubmit}
						>
							{!processingUpdate && <Trans>Confirm</Trans>}
							{processingUpdate && <CircularStatic visible={processingUpdate} />}
						</Button>
					)}
				</Grid>
				<Grid
					item
					xs={12}
					sx={{
						mb: 2,
						height: "2px",
						animationName: "blinker",
						animationDuration: "5000ms",
						animationTimingFunction: "linear",
						animationIterationCount: animation ? "infinite" : 0,
						"@-webkit-keyframes blinker": {
							from: { backgroundColor: animationColor },
							to: { backgroundColor: "white" },
						},
						"@keyframes blinker": {
							from: { backgroundColor: animationColor },
							to: { backgroundColor: "white" },
							// "0%": {
							// 	background: "#00ff44",
							// },
							// "20%": {
							// 	background: "#02d139",
						},
						textAlign: "left",
					}}
				>
					{animation && animationColor === "darkred" ? (
						<Typography sx={{ color: animationColor }}>error 😏</Typography>
					) : (
						animation && (
							<Typography sx={{ color: animationColor }}>
								<Trans>Profile updated</Trans> 🎉
							</Typography>
						)
					)}
				</Grid>
			</Grid>
		</Box>
	);
}

function ProfileMain(props: any) {
	const authContext = useContext(APIContext);
	const IntegrationSetting = useContext(IntegrationSettingsContext);

	const userInfo = authContext.userInfo({ loadAPI: false });
	if (userInfo === null) {
		return (
			<b>
				<Trans>Please wait, loading</Trans>
			</b>
		);
	}
	let personalTitle = t`Personal`;
	let consentTitle = t`Consent`;
	let cardTitle = t`Card`;
	let showCards = false;
	if (IntegrationSetting.profilePageItems.includes(ProfilePageItems.Cards)) {
		showCards = true;
	}
	let layout = Layouting();
	let minNavigationHight = 0;
	if (layout.fs) {
		delete layout["height"];
		delete layout["overflow"];

		const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
		minNavigationHight = 100 * (182 / vh);
	}

	return (
		<Card sx={layout}>
			<CardContent sx={{ padding: 0 }}>
				<HearderUI />
			</CardContent>
			<CardActions
				sx={{
					backgroundColor: (theme) => theme.palette.background.default,
					maxHeight: "246px",
					minHeight: () => {
						if (layout.fs) return `calc(100vh - ${minNavigationHight}vh)`;
					},
					overflow: "auto",
					pr: 2,
					pl: 2,
				}}
			>
				<ThreeTabs
					Label1={personalTitle}
					Panel1={<ProfileDetail editable={IntegrationSetting.enableProfileEdit} showAllFields memberDTO={userInfo} />}
					Label2={consentTitle}
					Panel2={<ConsentDetail editable={IntegrationSetting.enableConsentEdit} />}
					Label3={cardTitle}
					Panel3={showCards && <CardDetail editable={IntegrationSetting.enableCardEdit} name={userInfo.firstName + " " + userInfo.lastName} />}
				></ThreeTabs>
			</CardActions>
		</Card>
	);
}

function ProfileSmall() {
	const authContext = useContext(APIContext);
	const widgetSetting = useContext(ThemeSettingsContext);

	const userInfo = authContext.userInfo({ loadAPI: false });
	if (userInfo === null) {
		return (
			<b>
				<Trans>Please wait, loading</Trans>
			</b>
		);
	}

	let state = null;
	if (widgetSetting.overflow) {
		state = {
			height: "fit-content",
			overflow: "auto",
			boxShadow: "0",
			maxHeight: "180px",
		};
	}

	return (
		<Card sx={{ ...state, borderRadius: "unset" }}>
			<CardContent
				sx={{
					bgcolor: (theme) => theme.palette.background.default,
				}}
			>
				<ProfileDetail editable={false} showAllFields={false} memberDTO={userInfo} />
			</CardContent>
		</Card>
	);
}

function ProfileFull(props: any) {
	const authContext = useContext(APIContext);
	const IntegrationSetting = useContext(IntegrationSettingsContext);
	const userInfo = authContext.userInfo({ loadAPI: false });
	if (userInfo === null) {
		return (
			<b>
				<Trans>Please wait, loading</Trans>
			</b>
		);
	}
	let personalTitle = t`Personal`;
	let consentTitle = t`Consent`;
	let cardTitle = t`Card`;
	let showCards = false;
	if (IntegrationSetting.profilePageItems.includes(ProfilePageItems.Cards)) {
		showCards = true;
	}
	return (
		<Card sx={{ borderRadius: "unset", overflow: "none" }}>
			<CardContent sx={{ padding: 0 }}>
				<HearderUI />
			</CardContent>
			<CardActions
				sx={{
					backgroundColor: (theme) => theme.palette.background.default,
					height: window.innerHeight - FixedSizes.TOPBAR - FixedSizes.HEADER - FixedSizes.NAVBAR,
					overflow: "scroll",
					pr: 2,
					pl: 2,
				}}
			>
				<ThreeTabs
					Label1={personalTitle}
					Panel1={<ProfileDetail editable={IntegrationSetting.enableProfileEdit} showAllFields memberDTO={userInfo} />}
					Label2={consentTitle}
					Panel2={<ConsentDetail editable={IntegrationSetting.enableConsentEdit} />}
					Label3={cardTitle}
					Panel3={showCards && <CardDetail editable={IntegrationSetting.enableCardEdit} name={userInfo.firstName + " " + userInfo.lastName} />}
				></ThreeTabs>
			</CardActions>
		</Card>
	);
}

export function ProfileUI() {
	const userContext = useContext(UserContext);
	switch (userContext.widgetSizeState()) {
		case WidgetSize.Main:
			return <ProfileMain />;
		case WidgetSize.Small:
			return <ProfileSmall />;
		case WidgetSize.Full:
			return <ProfileFull />;
		default:
			return <ProfileMain />;
	}
}
function Layouting() {
	const widgetSetting = useContext(ThemeSettingsContext);
	let state = null;
	if (widgetSetting.overflow) {
		state = {
			height: "fit-content",
			overflow: "auto",
			boxShadow: "0",
		};
	}
	state = {
		...state,
		borderRadius: "0",
		fs: widgetSetting.floatInMobile,
	};
	return state;
}
