import { log } from "./service/clientlog";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { IntegrationSettings, IntegrationSettingsDefaults } from "./context/integrationsettingscontext";
import { IStorage, WidgetFunctions } from "./types/interface";
import { ConsoleType } from "./types/enum";

declare global {
	interface Window {
		CreateLoyaltyWidget: (is: IntegrationSettings, storage: IStorage) => Promise<WidgetFunctions>;
	}
}

function getScriptURL(): string {
	// return document?.getElementById("loyaltyscript")?.getAttribute('src') || null
	const url = document?.currentScript?.getAttribute("src") || "http://localhost:3000";
	let a = document.createElement("a");
	a.href = url;
	return a.protocol + "//" + a.host + "/";
}
declare var __webpack_public_path__: string;
__webpack_public_path__ = getScriptURL();
log("script URL:", ConsoleType.Trace, __webpack_public_path__);

window.CreateLoyaltyWidget = function (is: IntegrationSettings = IntegrationSettingsDefaults, storage: IStorage): Promise<WidgetFunctions> {
	is.authRequiredAccount ??= IntegrationSettingsDefaults.authRequiredAccount;
	is.authRequiredBirthdate ??= IntegrationSettingsDefaults.authRequiredBirthdate;
	is.authRequiredCard ??= IntegrationSettingsDefaults.authRequiredCard;
	is.authenticationTypes ??= IntegrationSettingsDefaults.authenticationTypes;
	is.benefits ??= IntegrationSettingsDefaults.benefits;
	is.container ??= IntegrationSettingsDefaults.container;
	is.containerThemeSettings ??= IntegrationSettingsDefaults.containerThemeSettings;
	is.customer ??= IntegrationSettingsDefaults.customer;
	is.defaultLanguage ??= IntegrationSettingsDefaults.defaultLanguage;
	is.defaultTheme ??= IntegrationSettingsDefaults.defaultTheme;
	is.displayName ??= IntegrationSettingsDefaults.displayName;
	is.emailOnConsent ??= IntegrationSettingsDefaults.emailOnConsent;
	is.enableCardEdit ??= IntegrationSettingsDefaults.enableCardEdit;
	is.enableConsentEdit ??= IntegrationSettingsDefaults.enableConsentEdit;
	is.enableDownloadReceipt ??= IntegrationSettingsDefaults.enableDownloadReceipt;
	is.enableProfileEdit ??= IntegrationSettingsDefaults.enableProfileEdit;
	is.enableReleaseBonus ??= IntegrationSettingsDefaults.enableReleaseBonus;
	is.enableReleaseGift ??= IntegrationSettingsDefaults.enableReleaseGift;
	is.enableReleaseOffer ??= IntegrationSettingsDefaults.enableReleaseOffer;
	is.languages ??= IntegrationSettingsDefaults.languages;
	is.menu ??= IntegrationSettingsDefaults.menu;
	is.noReleasableWithText ??= IntegrationSettingsDefaults.noReleasableWithText;
	is.optionalBirthdateOnConsent ??= IntegrationSettingsDefaults.optionalBirthdateOnConsent;
	is.palette ??= IntegrationSettingsDefaults.palette;
	is.phoneCountryCode ??= IntegrationSettingsDefaults.phoneCountryCode;
	is.popupTimeout ??= IntegrationSettingsDefaults.popupTimeout;
	is.postLoginWidgetMode ??= IntegrationSettingsDefaults.postLoginWidgetMode;
	is.postLoginWidgetSize ??= IntegrationSettingsDefaults.postLoginWidgetSize;
	is.product ??= IntegrationSettingsDefaults.product;
	is.profilePageItems ??= IntegrationSettingsDefaults.profilePageItems;
	is.programId ??= IntegrationSettingsDefaults.programId;
	is.releaseMode ??= IntegrationSettingsDefaults.releaseMode;
	is.releaseTimerMax ??= IntegrationSettingsDefaults.releaseTimerMax;
	is.settingPageItems ??= IntegrationSettingsDefaults.settingPageItems;
	is.showLevel ??= IntegrationSettingsDefaults.showLevel;
	is.storageId ??= is.container ?? IntegrationSettingsDefaults.storageId;
	is.terminalGUID ??= IntegrationSettingsDefaults.terminalGUID;
	is.themes ??= IntegrationSettingsDefaults.themes;
	is.topbar ??= IntegrationSettingsDefaults.topbar;
	is.topbarOnLoginRouter ??= IntegrationSettingsDefaults.topbarOnLoginRouter;
	is.onEvent ??= IntegrationSettingsDefaults.onEvent;
	is.onBonusReserved ??= IntegrationSettingsDefaults.onBonusReserved;
	is.getBonusReleased ??= IntegrationSettingsDefaults.getBonusReleased;
	is.deleteBonusReservation ??= IntegrationSettingsDefaults.deleteBonusReservation;
	is.onGiftReserved ??= IntegrationSettingsDefaults.onGiftReserved;
	is.getGiftReleased ??= IntegrationSettingsDefaults.getGiftReleased;
	is.onOfferReserved ??= IntegrationSettingsDefaults.onOfferReserved;
	is.getOfferReleased ??= IntegrationSettingsDefaults.getOfferReleased;
	is.onWidgetInitialize ??= IntegrationSettingsDefaults.onWidgetInitialize;

	const elem = document.getElementById(is.container as string);

	log("CreateLoyaltyWidget for elem:", ConsoleType.Trace, elem);

	const windowOutput: Promise<WidgetFunctions> = new Promise<WidgetFunctions>((resolve, reject) => {
		const wf = (widgetFunction: WidgetFunctions) => {
			resolve(widgetFunction);
			return true;
		};

		setTimeout(() => {
			log("React start rendering (0 second timeout)", ConsoleType.Trace, typeof windowOutput, is);
			const root = createRoot(elem!);
			root.render(
				<React.StrictMode>
					<App IntegrationSettings={is} WidgetFunctions={wf} />
				</React.StrictMode>
			);
		}, 0);
	});

	windowOutput.then(() => {
		log("React rendered", ConsoleType.Trace);
	});

	log("Before rendering, we have promise :)", ConsoleType.Trace, windowOutput);

	return windowOutput;
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
